import { takeLatest, call, put } from 'redux-saga/effects';

import api from '../api';
import {
    removeAffiliateRequest,
    removeAffiliateSuccess,
    removeAffiliateFailure,
    listAffiliatesRequest,
    listAffiliatesSuccess,
    listAffiliatesFailure,
    addAffiliateRequest,
    addAffiliateSuccess,
    addAffiliateFailure,
    updateAffiliateRequest,
    updateAffiliateSuccess,
    updateAffiliateFailure,
    getAffiliateStatsRequest,
    getAffiliateStatsSuccess,
    getAffiliateStatsFailure,
    Affiliate,
    affiliateStripeConnectRequest,
    AffiliateStripeConnectResponse,
    updateAffiliateStatsRequest,
} from './actions';

// API calls
const addAffiliateApi = async (affiliate: Affiliate): Promise<Affiliate> => {
    const response = await api.post("/api/affiliates/", affiliate);
    return response.data;
};

const removeAffiliateApi = async (affiliateId: number): Promise<void> => {
    await api.delete(`/api/affiliates/${affiliateId}`);
};

const listAffiliateApi = async (): Promise<Affiliate[]> => {
    const response = await api.get('/api/affiliates/');
    return response.data;
};

const updateAffiliateApi = async (affiliate: Affiliate): Promise<Affiliate> => {
    const response = await api.patch(`/api/affiliates/${affiliate.id}/`, affiliate);
    return response.data;
};

const affiliateStripeConnectApi = async (): Promise<AffiliateStripeConnectResponse> => {
    const response = await api.get('/api/affiliate/connect/');
    return response.data;
};

const getAffiliateStatsApi = async (): Promise<Affiliate> => {
    const response = await api.get('/api/affiliates/stats/');
    return response.data;
};

const updateAffiliateStatsApi = async (affiliate: Affiliate): Promise<Affiliate> => {
    const response = await api.post('/api/affiliates/updateme/', affiliate);
    return response.data;
};

// Sagas
function* handleAddAffiliate(action: ReturnType<typeof addAffiliateRequest>) {
    try {
        const data: Affiliate = yield call(addAffiliateApi, action.payload);
        yield put(addAffiliateSuccess(data));
    } catch (error: any) {
        yield put(addAffiliateFailure(error.response?.data || 'Failed to add affiliate'));
    }
}

function* handleRemoveAffiliate(action: ReturnType<typeof removeAffiliateRequest>) {
    try {
        yield call(removeAffiliateApi, action.payload.id);
        yield put(removeAffiliateSuccess(action.payload));
    } catch (error: any) {
        yield put(removeAffiliateFailure(error.response?.data || 'Failed to remove affiliate'));
    }
}

function* handleListAffiliate() {
    try {
        const data: Affiliate[] = yield call(listAffiliateApi);
        yield put(listAffiliatesSuccess(data));
    } catch (error: any) {
        yield put(listAffiliatesFailure(error.response?.data || 'Failed to list affiliates'));
    }
}

function* handleUpdateAffiliate(action: ReturnType<typeof updateAffiliateRequest>) {
    try {
        const data: Affiliate = yield call(updateAffiliateApi, action.payload.affiliate);
        if (action.payload.cb) {
            action.payload.cb(data);
        }
        yield put(updateAffiliateSuccess(data));
    } catch (error: any) {
        if (action.payload.cb) {
            action.payload.cb(null);
        }
        yield put(updateAffiliateFailure(error.response?.data || 'Failed to update affiliate'));
    }
}

function* handleGetAffiliateStats() {
    try {
        const data: Affiliate = yield call(getAffiliateStatsApi);
        yield put(getAffiliateStatsSuccess(data));
    } catch (error: any) {
        yield put(getAffiliateStatsFailure(error.response?.data || 'Failed to get affiliate stats'));
    }
}

function* handleAffiliateStripeConnect(action: ReturnType<typeof affiliateStripeConnectRequest>) {
    try {
        const data: AffiliateStripeConnectResponse = yield call(affiliateStripeConnectApi);
        action.payload.cb(data)
    } catch (error: any) {
        // yield put(affiliateStripeConnectFailure(error.response?.data || 'Failed to connect to Stripe'));
    }
}

function* handleUpdateAffiliateStats(action: ReturnType<typeof updateAffiliateStatsRequest>) {
    try {
        const data: Affiliate = yield call(updateAffiliateStatsApi, action.payload.affiliate);
        if (action.payload.cb) {
            action.payload.cb(data);
        }
        yield put(updateAffiliateSuccess(data));
    } catch (error: any) {
        if (action.payload.cb) {
            action.payload.cb(null);
        }
        yield put(updateAffiliateFailure(error.response?.data || 'Failed to update affiliate stats'));
    }
}
// Watcher sagas
export function* watchAffiliateSaga() {
    yield takeLatest(addAffiliateRequest.type, handleAddAffiliate);       // Listen for add affiliate request
    yield takeLatest(removeAffiliateRequest.type, handleRemoveAffiliate); // Listen for remove affiliate request
    yield takeLatest(listAffiliatesRequest.type, handleListAffiliate); // Listen for list affiliates request
    yield takeLatest(updateAffiliateRequest.type, handleUpdateAffiliate); // Listen for update affiliate request
    yield takeLatest(getAffiliateStatsRequest.type, handleGetAffiliateStats); // Listen for get affiliate stats request
    yield takeLatest(affiliateStripeConnectRequest.type, handleAffiliateStripeConnect); // Listen for affiliate stripe connect request
    yield takeLatest(updateAffiliateStatsRequest.type, handleUpdateAffiliateStats); // Listen for update affiliate stats request
}
