import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
    removeAffiliateRequest,
    removeAffiliateSuccess,
    removeAffiliateFailure,
    listAffiliatesRequest,
    listAffiliatesSuccess,
    listAffiliatesFailure,
    addAffiliateRequest,
    addAffiliateSuccess,
    addAffiliateFailure,
    resetErrorRequest,
    updateAffiliateRequest,
    updateAffiliateSuccess,
    updateAffiliateFailure,
    getAffiliateStatsRequest,
    getAffiliateStatsSuccess,
    getAffiliateStatsFailure,
    updateAffiliateStatsRequest,
    Affiliate
} from './actions';

export interface AffiliateState {
    affiliates: Affiliate[];
    stats: Affiliate | null;
    loading: boolean;
    error: string | null;
}

const initialState: AffiliateState = {
    affiliates: [],
    stats: null,
    loading: false,
    error: null,
};

const productReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(removeAffiliateRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(addAffiliateRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(listAffiliatesRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(addAffiliateSuccess, (state, action) => {
            state.affiliates.push(action.payload); // Add product to the list
            state.loading = false;
        })
        .addCase(removeAffiliateSuccess, (state, action: PayloadAction<{ id: number }>) => {
            state.affiliates = state.affiliates.filter(affiliate => affiliate.id !== action.payload.id);
            state.loading = false;
        })
        .addCase(listAffiliatesSuccess, (state, action) => {
            state.affiliates = action.payload; // Set products from the server
            state.loading = false;
        })
        .addCase(addAffiliateFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(removeAffiliateFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(resetErrorRequest, (state) => {
            state.error = null;
        })
        .addCase(listAffiliatesFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(updateAffiliateRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(updateAffiliateSuccess, (state, action) => {
            state.affiliates = state.affiliates.map((affiliate) =>
                affiliate.id === action.payload.id
                    ? { ...action.payload, user: affiliate.user }
                    : affiliate
            );
            if (action.payload.id === state.stats?.id) {
                state.stats = action.payload;
            }
            state.loading = false;
        })
        .addCase(updateAffiliateFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(getAffiliateStatsRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(getAffiliateStatsSuccess, (state, action) => {
            state.stats = action.payload;
            state.loading = false;
        })
        .addCase(getAffiliateStatsFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(updateAffiliateStatsRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
});

export default productReducer;
