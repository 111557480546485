import { useEffect, useState } from 'react';

import { config } from '../config';
import { ConfirmationDialog } from './ConfirmationDialog';

const VERSION_CHECK_INTERVAL = 5 * 60 * 1000; // 5 minutes

const VersionCheck = () => {
    const [showUpdateDialog, setShowUpdateDialog] = useState(false);
    const currentVersion = config.version;

    useEffect(() => {
        checkVersion;
        const versionCheckInterval = setInterval(checkVersion, VERSION_CHECK_INTERVAL);

        return () => {
            clearInterval(versionCheckInterval);
        };
    }, []);

    const handleUpdate = () => {
        window.location.reload();
    };

    const checkVersion = async () => {
        try {
            const response = await fetch(`/version.json?t=${new Date().getTime()}`);
            const data = await response.json();

            if (data.commit !== currentVersion) {
                setShowUpdateDialog(true);
            }
        } catch (error) {
            console.error('Error checking version:', error);
        }
    };

    return (
        <ConfirmationDialog
            isOpen={showUpdateDialog}
            onConfirm={handleUpdate}
            onCancel={() => setShowUpdateDialog(false)}
            title="New Version Available"
            message={<div className="text-center">
                <p>A new version of the application is available.</p>
                <p>Would you like to refresh the page now?</p>
            </div>}
        />
    );

};

export default VersionCheck;
