import { createAction } from '@reduxjs/toolkit';

export interface User {
    email: string;
    id?: number;
    first_name?: string;
    last_name?: string;
    is_staff?: boolean;
    is_superuser?: boolean;
    is_confirmed?: boolean;
    is_active?: boolean;
    is_affiliate?: boolean;
    has_active_plan?: boolean;
    has_seen_popup?: boolean;
    credits?: number;
    need_new_password?: boolean;
    last_newsletter_id?: number;
}

export interface LoginResponse {
    token: string;
    user: User;
    email: string;
}

export interface SignupResponse {
    result: string;
    token: string;
    user: User;
    message: string;
}

export interface EmailStatusResponse {
    status: string,
    message: string,
    result: boolean
}

export interface ResetPasswordResponse {
    status: string,
    message: string,
    result: string
}

export interface SystemStatsResponse {
    likes: number,
    users: number,
    videos: number,
    videos_this_week: number,
    videos_last_week: number,
    videos_this_month: number,
    downloads: number,
    views: number,
    news_total: number;
    news_last: number;
};

export interface SystemStatsResultsResponse {
    result: SystemStatsResponse;
};

export interface FeedbackResponse {
    result: boolean;
};

export interface ConstantsResponse {
    result: Record<string, string|number|FileList>;
};

export interface SubscriptionCreateResponse {
    id? : string;
    url?: string;
};

export interface SubscriptionManageResponse {
    id? : string;
    url?: string;
};

export interface NewsletterResponse {
    id: number;
    title: string;
    url: string;
};

// Define actions for auth
export const loginRequest = createAction<{ email: string; password: string }>('auth/loginRequest');
export const loginGoogleRequest = createAction<{ auth_code: string; }>('auth/loginGoogleRequest');
export const loginSuccess = createAction<LoginResponse>('auth/loginSuccess');
export const loginFailure = createAction<string>('auth/loginFailure');

export const deleteRequest = createAction('auth/deleteRequest');
export const deleteSuccess = createAction('auth/deleteSuccess');
export const deleteFailure = createAction<string>('auth/deleteFailure');

export const logoutRequest = createAction('auth/logoutRequest');

export const resetErrorRequest = createAction('auth/resetErrorRequest');

export const loadingOnRequest = createAction('auth/loadingOnRequest');
export const loadingOffRequest = createAction('auth/loadingOffRequest');

export const signupRequest = createAction<{ name: string, email: string; password: string }>('auth/signupRequest');
export const signupSuccess = createAction<SignupResponse>('auth/signupSuccess');
export const signupFailure = createAction<string>('auth/signupFailure');

export const checkEmailRequest = createAction<{ email: string}>('auth/checkEmailRequest');
export const checkEmailSuccess = createAction<EmailStatusResponse>('auth/checkEmailSuccess');
export const checkEmailFailure = createAction<string>('auth/checkEmailFailure');

export const resetPasswordRequest = createAction<{ email: string}>('auth/resetPasswordRequest');
export const resetPasswordSuccess = createAction<boolean>('auth/resetPasswordSuccess');
export const resetPasswordFailure = createAction<string>('auth/resetPasswordFailure');

export const systemStatsRequest = createAction('auth/systemStatsRequest');
export const systemStatsSuccess = createAction<SystemStatsResponse>('auth/systemStatsSuccess');
export const systemStatsFailure = createAction<string>('auth/systemStatsFailure');

export const constantsUpdateRequest = createAction<{ constants: Record<string, string|number|FileList>, cb?: any }>('auth/constantsUpdateRequest');
export const constantsResetRequest = createAction('auth/constantsResetRequest');
export const constantsRequest = createAction('auth/constantsRequest');
export const constantsSuccess = createAction<Record<string, string|number|FileList>>('auth/constantsSuccess');
export const constantsFailure = createAction<string>('auth/constantsFailure');

export const profileRequest = createAction('auth/profileRequest');
export const profileSuccess = createAction<User>('auth/profileSuccess');
export const profileFailure = createAction<string>('auth/profileFailure');

export const profileUpdateRequest = createAction<User>('auth/profileUpdateRequest');
export const profileUpdateSuccess = createAction<User>('auth/profileUpdateSuccess');
export const profileUpdateFailure = createAction<string>('auth/profileUpdateFailure');

export const onboardingRequest = createAction<{ subscribe: boolean }>('auth/onboardingRequest');
export const onboardingSuccess = createAction<User>('auth/onboardingSuccess');
export const onboardingFailure = createAction<string>('auth/onboardingFailure');

export const feedbackRequest = createAction<{ typ: string; title: string; message: string }>('auth/feedbackRequest');
export const feedbackSuccess = createAction<boolean>('auth/feedbackSuccess');
export const feedbackFailure = createAction<string>('auth/feedbackFailure');

export const newslettersSubscribeRequest = createAction('auth/newslettersSubscribeRequest');
export const newslettersSubscribeSuccess = createAction<User>('auth/newslettersSubscribeSuccess');
export const newslettersSubscribeFailure = createAction<string>('auth/newslettersSubscribeFailure');

export const newslettersRequest = createAction('auth/newslettersRequest');
export const newslettersSuccess = createAction<NewsletterResponse>('auth/newslettersSuccess');
export const newslettersFailure = createAction<string>('auth/newslettersFailure');

export const newslettersJoinRequest = createAction<{ email: string, cb: any }>('auth/newslettersJoinRequest');

export const subscribeRequest = createAction<{ plan_name: string, cb: any }>('auth/subscribeRequest');
export const subscriptionManageRequest = createAction<{ cb: any }>('auth/subscriptionManageRequest');
