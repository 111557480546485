import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
    removeClipRequest,
    removeClipSuccess,
    removeClipFailure,
    listClipsRequest,
    listClipsSuccess,
    listClipsFailure,
    listFavoriteClipsRequest,
    listFavoriteClipsSuccess,
    listFavoriteClipsFailure,
    addClipRequest,
    addClipSuccess,
    addClipFailure,
    viewClipRequest,
    viewClipSuccess,
    viewClipFailure,
    likeClipRequest,
    likeClipSuccess,
    likeClipFailure,
    unlikeClipRequest,
    unlikeClipSuccess,
    unlikeClipFailure,
    resetErrorRequest,
    Clip,
    listClipsNotReadyRequest,
    listClipsNotReadySuccess,
    listClipsNotReadyFailure,
} from './actions';

export interface ClipState {
    clips: Clip[];
    clips_not_ready: Clip[];
    favorite_clips: Clip[];
    loading: boolean;
    error: string | null;
}

const initialState: ClipState = {
    clips: [],
    clips_not_ready: [],
    favorite_clips: [],
    loading: false,
    error: null,
};

const clipReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(removeClipRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(removeClipSuccess, (state, action: PayloadAction<{ id: number }>) => {
            state.clips = state.clips.filter(clip => clip.id !== action.payload.id);
            state.loading = false;
        })
        .addCase(removeClipFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(viewClipRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(viewClipSuccess, (state) => {
            state.error = null;
            state.loading = false;
        })
        .addCase(viewClipFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(likeClipRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(likeClipSuccess, (state) => {
            state.error = null;
            state.loading = false;
        })
        .addCase(likeClipFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(unlikeClipRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(unlikeClipSuccess, (state) => {
            state.error = null;
            state.loading = false;
        })
        .addCase(unlikeClipFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(addClipRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(addClipSuccess, (state) => {
            state.error = null;
            state.loading = false;
        })
        .addCase(addClipFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(resetErrorRequest, (state) => {
            state.error = null;
        })
        .addCase(listFavoriteClipsRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(listFavoriteClipsSuccess, (state, action) => {
            state.favorite_clips = action.payload; // Set clips from the server
            state.loading = false;
        })
        .addCase(listFavoriteClipsFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(listClipsRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(listClipsSuccess, (state, action) => {
            state.clips = action.payload; // Set clips from the server
            state.loading = false;
        })
        .addCase(listClipsFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(listClipsNotReadyRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(listClipsNotReadySuccess, (state, action) => {
            state.clips_not_ready = action.payload;
            state.loading = false;
        })
        .addCase(listClipsNotReadyFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
});

export default clipReducer;
