import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { all } from 'redux-saga/effects';
import { combineReducers } from "redux";
import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { persistReducer, persistStore } from 'redux-persist';

import authReducer, { AuthState } from './auth/slice';
import clipReducer, { ClipState } from './clips/slice';
import staffReducer, { StaffState } from './staff/slice';
import productReducer, { ProductState } from './products/slice';
import categoryReducer, { CategoryState } from './categories/slice';
import affiliateReducer, { AffiliateState } from './affiliates/slice';

import { watchAuthSaga } from './auth/saga';
import { watchClipSaga } from './clips/saga';
import { watchStaffSaga } from './staff/saga';
import { watchProductSaga } from './products/saga';
import { watchCategorySaga } from './categories/saga';
import { watchAffiliateSaga } from './affiliates/saga';
const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

const sagaMiddleware = createSagaMiddleware();

// Setup Redux persist
const persistAuthConfig = {
    key: 'auth',
    blacklist: ['feedback', 'error', 'loading'],
    storage,
};
const persistCategoryConfig = {
    key: 'category',
    blacklist: ['error', 'loading'],
    storage,
};
const persistClipConfig = {
    key: 'clip',
    blacklist: ['error', 'loading'],
    storage,
};
const persistProductConfig = {
    key: 'product',
    blacklist: ['error', 'loading'],
    storage,
};
const persistAffiliateConfig = {
    key: 'affiliate',
    blacklist: ['error', 'loading'],
    storage,
};

const persistedAuthReducer = persistReducer(persistAuthConfig, authReducer);
const persistedCategoryReducer = persistReducer(persistCategoryConfig, categoryReducer);
const persistedClipReducer = persistReducer(persistClipConfig, clipReducer);
const persistedProductReducer = persistReducer(persistProductConfig, productReducer);
const persistedAffiliateReducer = persistReducer(persistAffiliateConfig, affiliateReducer);

export const store = configureStore({
    reducer: combineReducers({
        auth: persistedAuthReducer,
        staff: staffReducer,
        clips: persistedClipReducer,
        products: persistedProductReducer,
        categories: persistedCategoryReducer,
        affiliates: persistedAffiliateReducer,
        router: routerReducer,
    }),
    middleware: (getDefaultMiddleware: (arg0: { serializableCheck: boolean; }) => any[]) => getDefaultMiddleware({
        serializableCheck: false
    }).concat(routerMiddleware).concat(sagaMiddleware),
});
export const history = createReduxHistory(store);
export const persistor = persistStore(store);

export interface RootState {
    auth: AuthState;
    clips: ClipState;
    products: ProductState;
    categories: CategoryState;
    affiliates: AffiliateState;
    staff: StaffState;
}

function* rootSaga() {
    yield all([
        watchCategorySaga(),
        watchAuthSaga(),
        watchClipSaga(),
        watchStaffSaga(),
        watchProductSaga(),
        watchAffiliateSaga(),
    ]);
}

sagaMiddleware.run(rootSaga);
