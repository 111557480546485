import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


interface ProtectedRouteProps {
  children: ReactNode;
}

const AuthRedirect: React.FC<ProtectedRouteProps> = ({ children }) => {
    const user = useSelector((state: any) => state.auth.auth); // Access auth state

    if (user) {
        // If not authenticated, redirect to login
        if (user?.user?.is_staff || user?.user?.is_affiliate) {
            return <Navigate to="/dashboard/admin" replace />;
        } else {
            return <Navigate to="/dashboard" replace />;
        }
    }

    // If authenticated, render the child component
    return children;
};

export default AuthRedirect;
