import axios from 'axios';
import * as Sentry from '@sentry/react';
import { config } from '../config';
import Swal from 'sweetalert2';

const api = axios.create({
    baseURL: config.apiUrl,
});

api.interceptors.request.use(
    (config) => {
        // Retrieve token from localStorage or any secure storage
        const token = localStorage.getItem('authToken');

        if (token) {
            // Attach the token to the Authorization header
            config.headers['Authorization'] = `Token ${token}`;
        }

        return config;
    },
    (error) => {
        // Handle request error
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            // Clear auth token
            localStorage.removeItem('authToken');
            // Redirect to login page
            window.location.href = '/login';
        } else {
            let errorMessage = error.response?.data?.message || error.response?.data?.error;
            if (!errorMessage && typeof error.response?.data === 'object') {
                errorMessage = Object.values(error.response?.data).join('\n');
            }
            const errorText = errorMessage ? errorMessage : 'Something went wrong, please try again later!';
            Sentry.captureException(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: errorText as string
            });
            console.error('Error:', errorText);
        }
        return Promise.reject(error);
    }
);

export const secureApiDownload = async (url:string, filename: string, cb?: any) => {
    return secureDownload(config.apiUrl + url, filename, cb);
}

export const secureDownload = async (url:string, filename: string, cb?: any) => {
    try {
        const token = localStorage.getItem('authToken');
        const response = await axios({
            url: url,
            method: 'GET',
            responseType: 'blob',  // Important for file download
            headers: {
                Authorization: `Token ${token}`,  // Pass the token in the header
            },
        });

        // Create a link to trigger the download
        const link = document.createElement('a');
        link.target = '_blank'
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.download = filename;
        link.click();
        if (cb) {
            cb(200)
        }
    } catch (error: any) {
        if (error?.response?.status && cb) {
            cb(error.response.status)
        }
        console.error('Error downloading the file:', error);
    }
};

export default api;
