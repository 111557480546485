import React from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { renderToString } from 'react-dom/server';

interface ConfirmationDialogProps {
    isOpen: boolean;
    title: string;
    message: string | React.ReactNode;
    onConfirm: () => void;
    onCancel: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    isOpen,
    title,
    message,
    onConfirm,
    onCancel
}) => {
    if (!isOpen) return null;

    const swal_ctx: SweetAlertOptions = {
        title: title,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
    };

    if (typeof message === 'string') {
        swal_ctx.text = message;
    } else {
        swal_ctx.html = renderToString(message as React.ReactElement);
    }

    Swal.fire(swal_ctx).then((result) => {
        if (result.isConfirmed) {
            onConfirm();
        } else {
            onCancel();
        }
    });

    return null;
};
