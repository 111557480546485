import { createAction } from '@reduxjs/toolkit';
import { Category } from '../categories/actions';

// Define types for Clip
export interface Clip {
    id: number;
    img?: string;
    video?: string;
    url?: string;
    title: string;
    slug: string;
    src: number;
    has_like?: boolean;
    description: string;
    views: number;
    likes: number;
    length: number;
    downloads: number;
    username?: string;
    username_link?: string;
    link?: string;
    categories: Category[];
    status?: string;
    date_added?: string;
}

export interface likeResponse {
    result: string;
}

export interface unlikeResponse {
    result: boolean;
}

export interface HttpResponse {
    status: number;
    data: any;
}


export interface viewResponse {
    result: boolean;
}

// Define actions for clips
export const addClipRequest = createAction<{
    url: string;
    title: string;
    description: string;
    categories: number[];
    username: string;
    username_link: string;
    video_file: File;
    img: File;
}>("clips/addClipRequest");
export const addClipSuccess = createAction<Clip>('clips/addClipSuccess');
export const addClipFailure = createAction<string>('clips/addClipFailure');

export const removeClipRequest = createAction<{ id: number }>('clips/removeClipRequest');
export const removeClipSuccess = createAction<{ id: number }>('clips/removeClipSuccess');
export const removeClipFailure = createAction<string>('clips/removeClipFailure');

export const listClipsRequest = createAction<{ order_by: string }>('clips/listClipsRequest');
export const listClipsSuccess = createAction<Clip[]>('clips/listClipsSuccess');
export const listClipsFailure = createAction<string>('clips/listClipsFailure');

export const listClipsNotReadyRequest = createAction('clips/listClipsNotReadyRequest');
export const listClipsNotReadySuccess = createAction<Clip[]>('clips/listClipsNotReadySuccess');
export const listClipsNotReadyFailure = createAction<string>('clips/listClipsNotReadyFailure');

export const listFavoriteClipsRequest = createAction<{ order_by: string }>('clips/listFavoriteClipsRequest');
export const listFavoriteClipsSuccess = createAction<Clip[]>('clips/listFavoriteClipsSuccess');
export const listFavoriteClipsFailure = createAction<string>('clips/listFavoriteClipsFailure');

export const likeClipRequest = createAction<{ id: number, cb?: any }>('clips/likeClipRequest');
export const likeClipSuccess = createAction('clips/likeClipSuccess');
export const likeClipFailure = createAction<string>('clips/likeClipFailure');

export const viewClipRequest = createAction<{ id: number, cb?: any }>('clips/viewClipRequest');
export const viewClipSuccess = createAction<viewResponse>('clips/viewClipSuccess');
export const viewClipFailure = createAction<string>('clips/viewClipFailure');

export const unlikeClipRequest = createAction<{ id: number, cb?: any}>('clips/unlikeClipRequest');
export const unlikeClipSuccess = createAction('clips/unlikeClipSuccess');
export const unlikeClipFailure = createAction<string>('clips/unlikeClipFailure');

export const resetErrorRequest = createAction('clips/resetErrorRequest');
