import React, { Component, ReactNode } from "react";
import * as Sentry from "@sentry/react";
import { config } from "../config";

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        // Check for specific error message related to dynamic imports
        if (error.message.includes("Failed to fetch dynamically imported module")) {
            return { hasError: true };
        }
        return { hasError: false };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        // Log error details to an error reporting service if needed
        console.error("ErrorBoundary caught an error:", error, errorInfo);
    }

    componentDidUpdate(_: ErrorBoundaryProps, prevState: ErrorBoundaryState): void {
        if (this.state.hasError && !prevState.hasError) {
            if (config.env === "development") {
                console.error("ErrorBoundary caught an error");
            } else {
                // Optionally trigger a page reload or notify the user
                Sentry.captureException("ErrorBoundary caught an error");

                let do_refresh = true;
                const refresh_date = localStorage.getItem('refresh_date');
                let refresh_cnt = parseInt(localStorage.getItem('refresh_cnt') || '0');

                if (refresh_date) {
                    const minutes_since_refresh = (new Date().getTime() - parseInt(refresh_date)) / 60000;

                    if (minutes_since_refresh > 1) {
                        refresh_cnt = 0;
                    } else if (refresh_cnt > 3) {
                        do_refresh = false;
                    }
                }

                localStorage.setItem('refresh_cnt', (refresh_cnt + 1).toString());
                localStorage.setItem('refresh_date', new Date().getTime().toString());

                if (do_refresh) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }
            }
        }
    }

    render(): ReactNode {
        if (this.state.hasError) {
            // Display a fallback UI when an error occurs
            return <h1>Updating... Please wait.</h1>;
        }
        return this.props.children;
    }
}
