import { createAction } from '@reduxjs/toolkit';
import { User } from '../auth/actions';

export interface AffiliateStats {
    total_clicks: number;
    codes_used: number;
    conversions: number;
    total_earned: number;
}

export interface AffiliateTransaction {
    id: number;
    user: string;
    amount: number;
    type: string;
    date_added: Date;
}

export interface Affiliate {
    id?: number;
    user?: User;
    email?: string;
    commission_rate: number;
    recurring_months: number;
    discount_code: string;
    discount_perc?: number;
    balance?: number;
    has_seen_welcome_message?: boolean;
    has_finished_onboarding?: boolean;
    stats?: AffiliateStats[];
    transactions?: AffiliateTransaction[];
}

export interface AffiliateStripeConnectResponse {
    url: string;
}

// Define actions for affiliates
export const removeAffiliateRequest = createAction<{ id: number }>('affiliates/removeAffiliateRequest');
export const removeAffiliateSuccess = createAction<{ id: number }>('affiliates/removeAffiliateSuccess');
export const removeAffiliateFailure = createAction<string>('affiliates/removeAffiliateFailure');

export const updateAffiliateStatsRequest = createAction<{affiliate: Affiliate, cb?: (data: Affiliate|null) => void }>('affiliates/updateAffiliateStatsRequest');
export const updateAffiliateRequest = createAction<{affiliate: Affiliate, cb?: (data: Affiliate|null) => void }>('affiliates/updateAffiliateRequest');
export const updateAffiliateSuccess = createAction<Affiliate>('affiliates/updateAffiliateSuccess');
export const updateAffiliateFailure = createAction<string>('affiliates/updateAffiliateFailure');

export const getAffiliateStatsRequest = createAction('affiliates/getAffiliateStatsRequest');
export const getAffiliateStatsSuccess = createAction<Affiliate>('affiliates/getAffiliateStatsSuccess');
export const getAffiliateStatsFailure = createAction<string>('affiliates/getAffiliateStatsFailure');


export const affiliateStripeConnectRequest = createAction<{ cb: any }>('affiliates/affiliateStripeConnectRequest');

export const addAffiliateRequest = createAction<Affiliate>('affiliates/addAffiliateRequest');
export const addAffiliateSuccess = createAction<Affiliate>('affiliates/addAffiliateSuccess');
export const addAffiliateFailure = createAction<string>('affiliates/addAffiliateFailure');

export const listAffiliatesRequest = createAction('affiliates/listAffiliatesRequest');
export const listAffiliatesSuccess = createAction<Affiliate[]>('affiliates/listAffiliatesSuccess');
export const listAffiliatesFailure = createAction<string>('affiliates/listAffiliatesFailure');

export const resetErrorRequest = createAction('affiliates/resetErrorRequest');
