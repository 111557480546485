import * as Sentry from "@sentry/react";
import versionInfo from './version.json';

const configs = {
    development: {
        env: 'development',
        apiUrl: 'http://arch.local:8603',
        // apiUrl: 'https://reclipper.onpy.mx',
        affiliate_url: 'http://reclipper-local.onpy.mx:3000',
        version: versionInfo.commit,
        // STRIPE_PUBLIC_KEY: 'pk_test_51QVf2EBGzna3MdTWYkpcJiCwyQpfiGgzVmrVymopzI1roYwWIFPUqqAd1i4jRzum1bheWxnl6WNqvWBODKIkCYTS00WE4kRJ5P',
        ///DENIS MX
        // STRIPE_PUBLIC_KEY: 'pk_test_51QVeupBR01jjA6Ll8Xz2AbSSAgF53TrAPtsf17090laWPJaQe4ll8pbSBb1yepxwePx3de84UsN2Rk3k52mGcFWx00ixXuWZg4',
        STRIPE_PUBLIC_KEY: 'pk_test_51R05b9FlajsxKOwZSfnY03Td6AeEM3lFh7aDV2VSHaFSCEYgR5HEFfIFkddGYJvQkCXX10MPg0EZcAGfFOJBGpYO00Nklh1GbE',
        google_auth_client_id: '499004089622-kiuo5hbqlv2527qnusoha0b4n5s88ldb.apps.googleusercontent.com',
        google_auth_redirect_url: ''
,
    },
    production: {
        env: 'production',
        apiUrl: 'https://reclipper-api.onpy.mx',
        affiliate_url: 'https://reclipper.onpy.mx',
        version: versionInfo.commit,
        STRIPE_PUBLIC_KEY: 'pk_test_51QVGSNRptZVlKKV64QiIlNKS6GFMQAivS3aHwBFWOzwzqcion2JzYSUok7iUQxcY9sU3ievWO0o79M2Dol8x9Xwk005tRSdeWE',
        google_auth_client_id: '499004089622-kiuo5hbqlv2527qnusoha0b4n5s88ldb.apps.googleusercontent.com',
        google_auth_redirect_url: ''
    },
};

type Environment = 'development' | 'production';

const currentEnv: Environment = (process.env.NODE_ENV as Environment) || 'development';

export const initSentry = () => {
    if (currentEnv === 'production') {
        Sentry.init({
            dsn: "https://49d5286a731a37626bd1b3e78c7ab1d4@o4508424058765312.ingest.us.sentry.io/4508954050691072",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Tracing
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/reclipper\.onpy\.mx\/api/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
}

export const config = configs[currentEnv];
